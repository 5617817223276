import React, { useEffect, useState } from 'react'
import { KeywordSearch, RenderTabs } from 'components'
import Tabs from 'devextreme-react/tabs'
import LoadIndicator from 'devextreme-react/load-indicator'
import './preferred-route.scss'
import { useLogisticsService } from 'services'
import { useParamService } from 'services';
import { useManagerService } from 'services'
import { oktaAuth } from 'components/okta/SecureRouter'

const PreferredRoute = () => {

  const [selectedLoadNumber, setSelectedLoadNumber] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadSuccessful, setIsLoadSuccessful] = useState(false)
  const [route, setRoute] = useState(null)
  const [stops, setStops] = useState(null)
  const [preferredFuelStops, setPreferredFuelStops] = useState(null)
  const [fuelDCLocations, setFuelDCLocations] = useState(null)
  const [routeId, setRouteId] = useState(null)
  const [directionsReport, setDirectionsReport] = useState(null)
  const { getRoutePreferredFuelLocations, getFuelDCLocations, getDirectionsReport, setDriverView } = useLogisticsService()
  const { getIntValue, getStringValue } = useParamService()
  const { getBasicRouteByLoadNumber } = useManagerService()
  const [loadNumber] = useState(getStringValue('loadNumber'))

  const tabs = [
    {
      id: 0,
      text: 'map',
      icon: 'icon ion-map',
    },
    {
      id: 1,
      text: 'fuel',
      icon: 'icon ion-ios-location',

    },
    {
      id: 2,
      text: 'directions',
      icon: 'icon ion-merge',
    },
    // {
    //     id: 3,
    //     text: 'copilot',
    //     icon: 'airplane',
    // }
  ]

  const appHeight = () => {
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  useEffect(() => {
    window.addEventListener('resize', appHeight)
    appHeight()
  }, [])

  useEffect(() => {
    if (loadNumber != null) {
      setSelectedLoadNumber(loadNumber)
    }
    if (selectedLoadNumber) {
      setIsLoading(true);
      setIsLoadSuccessful(false)
    }
  }, [loadNumber, selectedLoadNumber])

  useEffect(() => {
    const getData = async () => {
      let respBasicRoute = null
      let user = null
      let respFuelStops = []
      let respFuelDCLocations = []
      let respDirectionsReport = null
      let loadFailure = false

      if (selectedLoadNumber) {
        const firstResponses = await Promise.all([
          getBasicRouteByLoadNumber(selectedLoadNumber), //Basic Route sets IDs for avoids and favors
          oktaAuth.getUser()
        ])
        respBasicRoute = firstResponses[0]
        user = firstResponses[1]

        if (respBasicRoute) {
          let coords = []
          respBasicRoute.mapping.forEach(stop => {
            coords.push({
              lat: stop.lat,
              lon: stop.lon,
            })
          })

          const secondResponses = await Promise.all([
            getRoutePreferredFuelLocations(respBasicRoute.mapping),
            getFuelDCLocations(respBasicRoute.mapping),
            getDirectionsReport(coords),
            setDriverView(user.preferred_username, selectedLoadNumber)
          ])

          respFuelStops = secondResponses[0]
          respFuelDCLocations = secondResponses[1]
          respDirectionsReport = secondResponses[2]
        }
      }
      setIsLoading(false);

      if (respBasicRoute) {
        setRoute(respBasicRoute ? respBasicRoute : null)
        setStops(respBasicRoute.mapping ? respBasicRoute.mapping : null)
        setPreferredFuelStops(respFuelStops && Array.isArray(respFuelStops) ? respFuelStops : [])
        setFuelDCLocations(respFuelDCLocations && Array.isArray(respFuelDCLocations) ? respFuelDCLocations : [])
        setDirectionsReport(respDirectionsReport.data ? respDirectionsReport.data : null)
        setRouteId(selectedLoadNumber)
        setIsLoadSuccessful(!loadFailure)
      }
    };

    if (isLoading && !isLoadSuccessful) {
      getData()
    }
  }, [isLoading, isLoadSuccessful, selectedLoadNumber]);

  const optionChangedHandler = (e) => {
    if (e.name === 'selectedIndex' && parseInt(e.value) >= 0 && parseInt(e.value) !== parseInt(selectedIndex)) {
      setSelectedIndex(e.value)
    }
  }

  return (
    <React.Fragment>
      <KeywordSearch keyword={selectedLoadNumber} onSearch={(keyword) => setSelectedLoadNumber(keyword)} placeholder="Enter load number..." />
      {isLoading ? (
        <div className="load-indicator-container">
          <LoadIndicator height={200} width={200} />
        </div>
      ) : isLoadSuccessful ? (
        <React.Fragment>
          <Tabs dataSource={tabs} selectedIndex={parseInt(selectedIndex)} onOptionChanged={optionChangedHandler} />
          <RenderTabs selectedIndex={selectedIndex} stops={stops} preferredFuelStops={preferredFuelStops} fuelDCLocations={fuelDCLocations} routeId={routeId} loadNumber={selectedLoadNumber} route={route} directionsReport={directionsReport} />
        </React.Fragment>
      ) : (
        selectedLoadNumber && <div className="card no-load-found">Load Not Found</div>
      )}
    </React.Fragment>
  );
};
export default PreferredRoute

