import { useAuthentication } from "services";
import axios, { AxiosResponse } from "axios";
import {
  AddImagePayload,
  GetCreateExpenseImage,
  DeleteExpenseResponse,
  ImageProfileId,
  SubmitExpensePayload,
  SubmitExpenseResponse,
  ValidateExpenseDataResponse,
  ValidationExpensePayload,
  BasicRoute,
} from "types";

const useManagerService = () => {
  const { getAuthHeader, getUser } = useAuthentication();

  const serviceUrl = process.env.REACT_APP_MANAGER_SERVICE;

  const getUserProfileId = async () => {
    const user = await getUser();
    if (user) {
      const employeeId: any = user.driverId;
      const response = await axios.post(
        `${serviceUrl}/User/ProfileId`,
        JSON.stringify({ EmployeeId: employeeId }),
        {
          headers: { ...(await getAuthHeader()) },
        }
      );
      
      const { data } = await response;
      if (data.id && data.employeeNumber) {
        return { id: data.id, driverEmployeeId: data.employeeNumber };
      }
      return;
    }
  };

  const addImage = async (
    formvalue: AddImagePayload
  ): Promise<GetCreateExpenseImage | undefined> => {
    const response: AxiosResponse<GetCreateExpenseImage> | void = await axios
      .post(`${serviceUrl}/Image/AddImage`, formvalue, {
        headers: { ...(await getAuthHeader()) },
      })
      .catch();
    if (response) {
      const data: GetCreateExpenseImage =
        response && response.data && (await response.data);
      return data;
    }
  };

  const deleteImage = async (formData: any) => {
    const response = await axios.post(
      `${serviceUrl}/Image/DeleteImage`,
      formData,
      {
        headers: await getAuthHeader(),
      }
    );
    const { data } = await response;
    return data;
  };

  const getExpenseTypes = async () => {
    const response = await axios.get(
      `${serviceUrl}/Expense/GetExpenseTypesForDrivers`,
      {
        headers: await getAuthHeader(),
      }
    );
    const { data } = await response;
    return data;
  };

  const createExpense = async (formvalue: any, UUID: string) => {
    const finalPayload = await { expense: formvalue, userId: UUID };
    const response = await axios.post(
      `${serviceUrl}/Expense/createExpense`,
      finalPayload,
      {
        headers: { ...(await getAuthHeader()) },
      }
    );

    if (response) {
      const data = await response.data;
      return data;
    }
    return;
  };

  const getImage = async (
    imageProfileId: ImageProfileId
  ): Promise<string | undefined> => {
    const response = await axios.get(
      `${serviceUrl}/Image/GetImage/${imageProfileId}`,
      {
        headers: await getAuthHeader(),
      }
    );
    if (response) {
      const data: string | undefined =
        response && response.data ? response.data : undefined;
      return data;
    }
  };

  const submitExpense = async (
    formvalue: SubmitExpensePayload[],
    UUID: string
  ): Promise<SubmitExpenseResponse[] | undefined> => {
    const finalPayload = { expenses: formvalue, userId: UUID };
    const response = await axios.post(
      `${serviceUrl}/Expense/SubmitExpenses`,
      finalPayload,
      {
        headers: await getAuthHeader(),
      }
    );
    if (response) {
      const data: SubmitExpenseResponse[] =
        response && response.data && (await response.data);
      return data;
    }
  };

  const deleteExpense = async (
    formvalue: { id?: number },
    UUID: string
  ): Promise<DeleteExpenseResponse | undefined> => {
    const finalPayload = { ...formvalue, userId: UUID };
    const response = await axios.post(
      `${serviceUrl}/Expense/DeleteExpense`,
      finalPayload,
      {
        headers: { ...(await getAuthHeader()) },
      }
    );
    if (response) {
      const data: DeleteExpenseResponse =
        response && response.data && (await response.data);
      return data;
    }
  };

  const validateExpenses = async (
    formvalue: ValidationExpensePayload[]
  ): Promise<ValidateExpenseDataResponse | undefined> => {
    const finalPayload = [formvalue];
    const response = await axios.post(
      `${serviceUrl}/Expense/ValidateExpenses`,
      finalPayload,
      {
        headers: { ...(await getAuthHeader()) },
      }
    );
    if (response) {
      const data: ValidateExpenseDataResponse =
        response && response.data && (await response.data);
      return data;
    }
  };

  const GetDriverProfileByUserId = async (userID: any) => {
    const response = await axios.get(
      `${serviceUrl}/Expense/GetDriverProfileByUserId/${userID}`,
      {
        headers: await getAuthHeader(),
      }
    );
    if (response && response.data) {
      const data = response.data;
      return data;
    }
    return;
  };
  const filteredExpenses = async (formvalue: any): Promise<any> => {
    const response = await axios.post(
      `${serviceUrl}/Expense/GetFilteredExpenses`,
      JSON.stringify(formvalue),
      {
        headers: { ...(await getAuthHeader()) },
      }
    );
    const data =
      response && response.data && Array.isArray(response.data)
        ? response.data
        : [];
    return data;
  };

  const getDriverName = async (userID: any) => {
    const response = await axios.get(
      `${serviceUrl}/Expense/GetDriverProfileByUserId/${userID}`,
      {
        headers: await getAuthHeader(),
      }
    );
    if (response && response.data) {
      const data =
        response.data.firstName +
        " " +
        response.data.lastName +
        ", Employee #:  " +
        response.data.employeeId;
      return data;
    }
    return;
  };

  const getBasicRouteByLoadNumber = async (loadNumber: string): Promise<BasicRoute | undefined> => {
    const response: AxiosResponse<BasicRoute> | void = await axios.get(
      `${serviceUrl}/Route/BasicRouteByLoadNumber?loadNumber=${loadNumber}`,
      {
        headers: { ...(await getAuthHeader()) },
      }
    );

    const data: BasicRoute | undefined = response && response.data ? response.data : undefined
    if (data) data.type = 'BasicRoute'
    return data
  };

  return {
    getDriverName: getDriverName,
    filteredExpenses: filteredExpenses,
    addImage: addImage,
    deleteImage: deleteImage,
    getUserProfileId: getUserProfileId,
    getExpenseTypes: getExpenseTypes,
    createExpense: createExpense,
    validateExpenses: validateExpenses,
    deleteExpense: deleteExpense,
    getImage: getImage,
    submitExpense: submitExpense,
    GetDriverProfileByUserId: GetDriverProfileByUserId,
    getBasicRouteByLoadNumber: getBasicRouteByLoadNumber,
  };
};

export default useManagerService;