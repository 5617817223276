import React, { useState } from 'react'

const DemoLoad = () => {
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false)
    const [isLoadReady, setIsLoadReady] = useState<boolean>(false)
    const [isShowDetails, setIsShowDetails] = useState<boolean>(false)

    return (
        <div>
            { isShowDetails 
                ? <img src="/images/AppointmentDetails.PNG" alt=""/>
                : isConfirmed
                    ? isLoadReady
                        ? <img src="/images/ConfirmedReady.PNG" alt="" onClick={() => setIsShowDetails(true)} />
                        : <img src="/images/ConfirmedWaiting.PNG" alt="" onClick={() => setIsLoadReady(true)} />
                    : <img src="/images/Unconfirmed.PNG" alt="" onClick={() => setIsConfirmed(true)} />
            }
        </div>
    )
}

export default DemoLoad;