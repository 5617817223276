import React, { useState, useEffect } from 'react';
import Tabs from 'devextreme-react/tabs';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import '@trimblemaps/trimblemaps-js/trimblemaps.css';
import './map.scss'
import { createRoute, addElementsToMap } from '../mapLib';

const Map = ({ routeId, route, preferredFuelStops, fuelDCLocations }) => {
    const [mapStyle, setMapStyle] = useState(0)
    const [hasRequiredInformation, setHasRequiredInformation] = useState(false)
    const tabs = [
        {
            id: 0,
            text: TrimbleMaps.Common.Style.TRANSPORTATION,
            icon: 'icon ion-android-car',
        },
        {
            id: 1,
            text: TrimbleMaps.Common.Style.SATELLITE,
            icon: 'icon ion-android-globe',
        },
    ];

    useEffect(() => {
        if (routeId && route && preferredFuelStops && fuelDCLocations) {
            setHasRequiredInformation(true)
        }
    }, [routeId, route, preferredFuelStops, fuelDCLocations])

    useEffect(() => {
        if (hasRequiredInformation) {
            TrimbleMaps.APIKey = process.env.REACT_APP_MAPPING_API_KEY;

            let map = new TrimbleMaps.Map({
                container: 'map',
                center: [-90.755043, 39.976473], // DOT HQ
                style: tabs.find((element) => element.id === mapStyle).text,
            });

            let nav = new TrimbleMaps.NavigationControl();
            nav.showCompass = false;
            map.addControl(nav, 'bottom-right');

            let setIds = []
            if (route.type === 'BasicRoute') setIds = route.setIds

            const designatedRoute = createRoute(`designated-route-${routeId}`, route.mapping, setIds)

            map.on('load', () => {
              designatedRoute.addTo(map)
              addElementsToMap(map, route, preferredFuelStops, fuelDCLocations)

              designatedRoute.frameRoute();
            });
        }
    }, [hasRequiredInformation, mapStyle])

    const onOptionChanged = (e) => {
        if (e.name === 'selectedIndex' && parseInt(e.value) >= 0 && parseInt(e.value) !== parseInt(mapStyle)) {
            setMapStyle(e.value);
        }
    };

    return (
        <>
            <div id="map"></div>
            <Tabs dataSource={tabs} selectedIndex={parseInt(mapStyle)} onOptionChanged={onOptionChanged} />
        </>
    );
};
export default Map;