import React from "react";
import { Button } from 'devextreme-react/button';
import "./confirm-button.scss";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ loadNumber, onConfirmClick }) => {

    return (
        <Button className="confirm-button-button" icon="check"
            text="Confirm"
            onClick={() => onConfirmClick(loadNumber)}
        />
    );
}



