import TrimbleMaps, { GeoJSONSourceRaw } from '@trimblemaps/trimblemaps-js';
import { DEFAULT_MAP_CENTER, ROUTE_OPTIONS } from 'config/mapping'
import { BasicRoute, Coordinate, FuelLocation, RouteStop } from 'types'

export const createRoute = (routeId: string, stops: RouteStop[] | Coordinate[], setIds: number[] = []): TrimbleMaps.Route => {
  return new TrimbleMaps.Route({
    routeId: routeId,
    afSetIDs: setIds,
    stops: convertStopsToTrimbleLngLat(stops),
    ...ROUTE_OPTIONS,
  })
}

const convertStopsToTrimbleLngLat = (stops: RouteStop[] | Coordinate[]) => {
  const retVal: TrimbleMaps.LngLat[] = []
  if (stops && Array.isArray(stops)) {
    stops.forEach((stop) => {
      if (stop.lat != null && stop.lon !== null) {
        retVal.push(new TrimbleMaps.LngLat(stop.lon, stop.lat))
      }
    })
  }
  return retVal
}

const convertPreferredFuelStopsToGeoJSON = (preferredFuelStops: FuelLocation[]): GeoJSONSourceRaw => {
  let features: GeoJSON.Feature[] = [];

  if (preferredFuelStops && preferredFuelStops.length > 0) {
    preferredFuelStops.forEach((preferredFuelStop) => {
      features.push({
        type: 'Feature',
        properties: {
          name: preferredFuelStop.name,
          street: preferredFuelStop.address.street,
          city: preferredFuelStop.address.city,
          state: preferredFuelStop.address.state,
          zip: preferredFuelStop.address.zip,
        } as GeoJSON.GeoJsonProperties,
        geometry: {
          type: 'Point',
          coordinates: [preferredFuelStop.coords.lon, preferredFuelStop.coords.lat],
        } as GeoJSON.Geometry,
      } as GeoJSON.Feature);
    })
  }

  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: features,
    },
  } as GeoJSONSourceRaw
}

export const createMap = (currentMap: TrimbleMaps.Map | undefined): TrimbleMaps.Map => {
  if (currentMap) {
    currentMap.remove()
  }

  const map = new TrimbleMaps.Map({
    container: 'map',
    center: DEFAULT_MAP_CENTER,
    renderWorldCopies: false,
  })

  map.addControl(
    new TrimbleMaps.NavigationControl({
      showCompass: false,
    }),
    'top-right'
  )

  map.addControl(
    new TrimbleMaps.ScaleControl({
      maxWidth: 80,
      unit: 'imperial',
    })
  )

  return map
}

export const addPreferredFuelStopsToMap = (map: TrimbleMaps.Map, preferredFuelStops: FuelLocation[], fuelDCLocations: FuelLocation[]): void => {
  if (preferredFuelStops.length <= 0) return

  if (map.getLayer('poiPoints')) {
    map.removeLayer('poiPoints')
    map.removeSource('poiSource')
    map.removeImage('poi-marker-icon')
  }

  const poiPoints: FuelLocation[] = preferredFuelStops.concat(fuelDCLocations)
  if (poiPoints && Array.isArray(poiPoints)) {
    map.addSource('poiSource', convertPreferredFuelStopsToGeoJSON(poiPoints))
    map.loadImage(`${process.env.PUBLIC_URL}/fuel.png`, (_error: TrimbleMaps.ErrorEvent, image: HTMLImageElement | ArrayBufferView) => {
      map.addImage('poi-marker-icon', image)
      map.addLayer({
        id: 'poiPoints',
        type: 'symbol',
        source: 'poiSource',
        layout: {
          'icon-image': 'poi-marker-icon',
          'icon-size': 1,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
        } as TrimbleMaps.SymbolLayout,
      } as TrimbleMaps.SymbolLayer)

      map.on('click', 'poiPoints', (evt: TrimbleMaps.MapMouseEvent & { features?: TrimbleMaps.GeoJSONFeature[] | undefined } & TrimbleMaps.EventData) => {
        if (
          evt &&
          evt != null &&
          evt.features &&
          Array.isArray(evt.features) &&
          evt.features.length > 0 &&
          evt.features[0].properties &&
          evt.features[0].geometry.type == 'Point'
        ) {
          const popupLocation = new TrimbleMaps.LngLat(evt.features[0].geometry.coordinates[0], evt.features[0].geometry.coordinates[1])
          let popupContent = `<div class="poi-popup-header">${evt.features[0].properties.name}</div>`
          popupContent = `${popupContent}<div class="poi-popup-address">${evt.features[0].properties.street}</div>`
          popupContent = `${popupContent}<div class="poi-popup-address">${evt.features[0].properties.city}, ${evt.features[0].properties.state} ${evt.features[0].properties.zip}</div>`

          new TrimbleMaps.Popup().setLngLat(popupLocation).setHTML(popupContent).addTo(map)
        }
      })

      map.on('mouseenter', 'poiPoints', () => {
        map.getCanvas().style.cursor = 'pointer'
      })

      map.on('mouseleave', 'poiPoints', () => {
        map.getCanvas().style.cursor = ''
      })
    })
  }
}


export const addElementsToMap = (
  map: TrimbleMaps.Map,
  route: BasicRoute,
  preferredFuelStops: FuelLocation[],
  fuelDCLocations: FuelLocation[]
): void => {
  addPreferredFuelStopsToMap(map, preferredFuelStops, fuelDCLocations)
}