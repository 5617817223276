import React, { FC, Fragment } from 'react';
import "./header-box.scss";

type Props = {
    headerTitle: string,
    changed: boolean,
    displayStatus ?: string
    isLoadReady?: boolean
}

const HeaderBox: FC<Props> = ({ headerTitle, changed, displayStatus, isLoadReady }) => {

    var classTitleName = 'header-box-heading-item-title-';
    var loadStatusTextClassName = '';
    
    if (headerTitle !== null && headerTitle.length > 0) {
        classTitleName += headerTitle.replaceAll(' ', '').toLowerCase();

        if (displayStatus !== undefined || displayStatus !== null) {
            loadStatusTextClassName = isLoadReady === true
            ? "header-box-heading-item-title-confirmed-ready" 
            : "header-box-heading-item-title-confirmed-waiting";

    }
    else {
        classTitleName +=  "unconfirmed";
        }
    }

    return (
        <Fragment>
            {
                <div className={'header-box-heading-item-container'} >
                    <div className={classTitleName} >{headerTitle} </div>
                    {displayStatus && <div className={loadStatusTextClassName}>{displayStatus}</div>}
                    {changed && <div className='header-box-changed'>(STOPS CHANGED)</div>}
                </div>
            }
        </Fragment>
    );
};

export default HeaderBox