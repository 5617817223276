import React, { FC, useEffect, useState } from "react";
import { saleAmountHeaderFilter } from "../../helpers/ApplyFilters";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
} from "devextreme-react/data-grid";
import PopupComponent from "components/PopupComponent/PopupComponent";
import ImagePreview from "../ImagePreview/ImagePreview";
import "./ExpenseDataGrid.scss";
import { useManagerService } from "services";
import { useScreenSize } from "../../utils/media-query";

interface Props {
  dataGridValue?: any;
  onRowRemoving?: (e: any) => void;
  onRowRemoved?: (e: any) => void;
  canDisplayIcons?: (e: any) => boolean;
  handleEditDenyModal?: (e: any) => void;
  showFilterRow?: boolean;
  currentFilter?: any;
  showHeaderFilter?: boolean;
  calculateFilterExpression?: (
    value: any,
    selectedFilterOperations: any,
    target: any
  ) => void;
  orderHeaderFilter?: (data: any) => void;
  saleAmountEditorOptions?: any;
  handleImagePreview?: (e: any) => void;
  popupVisible?: boolean;
  hideReceiptInfo?: (e?: any) => void;
  closeButtonOptions?: any;
  canShowReceipt?: boolean;
  baseImageValue?: string;
  canShowSubmitExpense?: boolean;
  handleDeleteExpense?: (e: any) => void;
  filterExpense?: any;
}

const ExpenseDataGrid: FC<Props> = ({
  dataGridValue,
  onRowRemoved,
  canDisplayIcons,
  handleEditDenyModal,
  showFilterRow,
  currentFilter,
  showHeaderFilter,
  saleAmountEditorOptions,
  handleImagePreview,
  popupVisible,
  hideReceiptInfo,
  closeButtonOptions,
  canShowReceipt,
  baseImageValue,
  canShowSubmitExpense,
  handleDeleteExpense,
  filterExpense,
}) => {
  const screenSize = useScreenSize();
  const { getUserProfileId, getDriverName } = useManagerService();

  const [driverName, setDriverName] = useState<any>("");

  dataGridValue = React.createRef();

  const getInitialData = async () => {
    const userId: any = await getUserProfileId();
    if (userId && userId.id) {
      const driverName = await getDriverName(userId.id);
      setDriverName(driverName);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const showReceiptPreview = (e: any) => {
    return (
      <div>
        <button
          className="preview-receipt-btn"
          onClick={() =>
            handleImagePreview && handleImagePreview(e.row.data.imageProfileId)
          }
        >
          PREVIEW
        </button>
      </div>
    );
  };

  const showActionButton = (e: any) => {
    let displayIcon = false;
    if (canDisplayIcons) {
      displayIcon = canDisplayIcons(e);
    }

    return (
      <div>
        <button
          className="action-edit-btn"
          disabled={!displayIcon}
          style={{ width: "8rem" }}
          onClick={() =>
            displayIcon && handleEditDenyModal && handleEditDenyModal(e)
          }
        >
          RE-SUBMIT
        </button>
      </div>
    );
  };

  const convertNameToValues = (value: string) => {
    switch (value) {
      case "Created":
        return 1;
      case "Submitted":
        return 2;
      case "Approved":
        return 3;
      case "Denied":
        return 4;
      case "Processed":
        return 5;
      default:
        break;
    }
    return 5;
  };

  const sortingMethods = (value1: any, value2: any) => {
    // Handling null values
    if (!value1 && value2) return -1;
    if (!value1 && !value2) return 0;
    if (value1 && !value2) return 1;

    // Determines whether two strings are equivalent in the current locale
    return convertNameToValues(value1) - convertNameToValues(value2);
  };

  return (
    <>
      <div>
        <br />
        <br />
        <p className="driver-info">{driverName} Expenses</p>
        <DataGrid
          id="gridContainer"
          ref={dataGridValue}
          dataSource={filterExpense}
          keyExpr="id"
          showBorders={true}
          rowAlternationEnabled={true}
          hoverStateEnabled={false}
          onRowRemoving={handleDeleteExpense}
          onRowRemoved={onRowRemoved}
          columnHidingEnabled={true}
        >
          <Grouping autoExpandAll={false} expandMode={"rowClick"} />
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <SearchPanel visible={screenSize.isXSmall} placeholder="Search..." />
          <Column
            defaultGroupIndex={0}
            dataField={"expenseStatus.description"}
            caption={"EXPENSE STATUS"}
            sortingMethod={sortingMethods}
          >
            <HeaderFilter allowSearch={true} />
          </Column>
          <Column
            dataField="requestedAmount"
            caption={"REQUESTED AMOUNT"}
            alignment="left"
            dataType="number"
            format={{
              style: "currency",
              currency: "USD",
            }}
            width={screenSize.isXSmall && 130}
            editorOptions={{
              ...saleAmountEditorOptions,
            }}
          >
            <HeaderFilter dataSource={saleAmountHeaderFilter} />
          </Column>
          <Column dataField="expenseType.description" caption="EXPENSE TYPE">
            <HeaderFilter allowSearch={true} />
          </Column>
          <Column
            dataField="submitDate"
            sortOrder={"desc"}
            caption="SUBMIT DATE"
            alignment="left"
            dataType="datetime"
            format="M/d/yyyy"
            width={screenSize.isXSmall && 100}
          />
          <Column
            caption="RECEIPT"
            alignment="left"
            cellRender={showReceiptPreview}
          />
          <Column dataField="comment" caption="COMMENT">
            <HeaderFilter allowSearch={true} />
          </Column>
          <Column cellRender={showActionButton} />
        </DataGrid>
        {canShowReceipt && !canShowSubmitExpense && (
          <PopupComponent
            popupVisible={popupVisible}
            hideInfo={hideReceiptInfo}
            closeButtonOptions={closeButtonOptions}
            title="RECEIPT PREVIEW"
          >
            <ImagePreview baseImageValue={baseImageValue} />
          </PopupComponent>
        )}
      </div>
    </>
  );
};

export default ExpenseDataGrid;
