import axios from 'axios';
import notify from 'devextreme/ui/notify';
import { useAuthentication } from 'services'

const useLogisticsService = () => {

    const service = `${process.env.REACT_APP_LOGISTICS_SERVICE}`;

    const { getAuthHeader } = useAuthentication()

    const getRequestTimeout = async () => {
        return 90 * 1000
    }

    const getContentHeader = async (dataVersion = undefined) => {
        let authHeader = {
          'Content-Type': 'application/json',
          'Content-Encoding': 'gzip',
        }

        if (dataVersion) {
            authHeader = { ...authHeader, 'api-version': dataVersion }
        }

        return authHeader
    }
    const getLoadByLoadNumber = async function (loadNumber) {

        return await axios
            .get(`${service}/Load/ByLoadNumber/${loadNumber}?useDotPreferredRoute=true`, {
                headers: await getAuthHeader(),
            })
            .then((response) => {
                 let respVal = [];

                 if (response && response.data) {
                    respVal = response.data;
                 }

                 return { data: respVal };
            })
            .catch((error) => {
                if (error.response) {
                   // NON-200 RECEIVED
                   console.log(error);
                } else if (error.request) {
                  // REQUEST MADE, NO RESPONSE
                  console.log(error.request);
                } else {
                  // ERROR WITH REQUEST
                  console.log(error.message);
                }
                console.log(error.config);

                notify('Error retrieving load information.', 'error', 5000);
                return Promise.resolve({ data: [] });
            });
    };

    const getRoutePreferredFuelLocations = async (stops) => {
        const coords = []
        stops.forEach((stop) => {
            coords.push({ lat: stop.lat, lon: stop.lon, isWaypoint: false })
        })

        const response = await axios
            .post(`${service}/Fuel/GetRoutePreferredFuelLocations`, JSON.stringify(coords), {
              timeout: await getRequestTimeout(),
              headers: { ...(await getAuthHeader()), ...(await getContentHeader()) },
        })
        const data = response && response.data && Array.isArray(response.data) ? response.data : []
        return data
    }

    const getFuelDCLocations = async (stops) => {
        const coords = []
        stops.forEach((stop) => {
            coords.push({ lat: stop.lat, lon: stop.lon, isWaypoint: false })
        })
        const response = await axios
            .post(`${service}/Fuel/GetDCLocations`, JSON.stringify(coords), {
                timeout: await getRequestTimeout(),
                headers: { ...(await getAuthHeader()), ...(await getContentHeader()) },
            })

        const data = response && response.data && Array.isArray(response.data) ? response.data : []
        return data
    }

    const getDirectionsReport = async function (stops) {

        return await axios
            .post(`${service}/Route/DirectionsReport`, stops, {
                headers: await getAuthHeader(),
            })
            .then((response) => {
                let respVal = [];

                if (response && response.data) {
                    respVal = response.data;
                }

                return { data: respVal };
            })
            .catch((error) => {
                if (error.response) {
                // NON-200 RECEIVED
                console.log(error);
            } else if (error.request) {
                // REQUEST MADE, NO RESPONSE
                console.log(error.request);
            } else {
                // ERROR WITH REQUEST
                console.log(error.message);
            }
            console.log(error.config);

            notify('Error retrieving directions report.', 'error', 5000);
            return Promise.resolve({ data: [] });
        });
    };

    const setDriverView = async function (username, loadNumber) {

        return await axios
            .post(`${service}/Load/DriverView/${encodeURIComponent(username)}/${encodeURIComponent(loadNumber)}`, undefined, {
                headers: await getAuthHeader(),
            })
            .catch((error) => {
                if (error.response) {
                    // NON-200 RECEIVED
                    console.log(error);
                } else if (error.request) {
                    // REQUEST MADE, NO RESPONSE
                    console.log(error.request);
                } else {
                    // ERROR WITH REQUEST
                    console.log(error.message);
                }
                console.log(error.config);

                notify('Error updating the driver view of the preferred route.', 'error', 5000);
                return Promise.resolve(undefined);
            });
    };

    const sendPreferredRouteToTrimbleTripManagement = async function (loadNumber) {
        return await axios.post(
            `${service}/PreferredRoute/SendPreferredRouteToTrimbleTripManagement?loadNumber=${loadNumber}`,
            undefined,
            {
              headers: await getAuthHeader(),
            })
    };

    return {
        getRoutePreferredFuelLocations: getRoutePreferredFuelLocations,
        getLoadByLoadNumber: getLoadByLoadNumber,
        getFuelDCLocations: getFuelDCLocations,
        getDirectionsReport: getDirectionsReport,
        setDriverView: setDriverView,
        sendPreferredRouteToTrimbleTripManagement: sendPreferredRouteToTrimbleTripManagement
    };
};
export default useLogisticsService;
